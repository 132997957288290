import React from 'react';
import ProductList from './components/ProductList';
import Menu from './Pages/Menu'

function App() {
  return (
    <div className="App">
      <Menu />
      <ProductList categoriaId={1500} />
    </div>
  );
}

export default App;
