import React, { useState, useEffect, useCallback } from 'react';
import ProductItem from './ProductItem';
import SearchBar from './SearchBar';
import LoadingSpinner from './LoadingSpinner';
import { fetchProductList, fetchMoreProductList, applyFilters } from '../utils/utils';
import '../Styles/App.css';

function ProductList({ categoriaId }) {
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    minPrice: '',
    maxPrice: '',
    recent: false,
    discount: false,
  });
  const limit = 60;

  useEffect(() => {
    fetchProductList(categoriaId, setProductList, setLoading, setHasMoreItems, limit, true);
  }, [categoriaId]);

  useEffect(() => {
    if (page > 1) {
      fetchMoreProductList(page, setProductList, setLoading, setHasMoreItems, limit);
    }
  }, [page]);

  const handleScroll = useCallback(() => {
    if (window.innerHeight + window.scrollY >= document.documentElement.offsetHeight - 500 && !loading && hasMoreItems) {
      setPage(prevPage => prevPage + 1);
    }
  }, [loading, hasMoreItems]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <div className="product-list-container">
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} filters={filters} setFilters={setFilters} />
      <div className="product-list">
        {loading && productList.length === 0 ? (
          <LoadingSpinner />
        ) : (
          applyFilters(productList, filters)
            .filter(item => item.nome_produto.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((item, index) => (
              <ProductItem key={index} item={item} />
            ))
        )}
        {loading && productList.length > 0 && (
          <LoadingSpinner />
        )}
        {!loading && !hasMoreItems && (
          <div className="no-more-items">
            Não há mais itens para carregar.
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductList;
