import React from 'react';
import { imagem, handleCardClick } from '../utils/utils';
function ProductItem({ item }) {
  let rating;
  let imagem2 = imagem(item.empresa)
  if(item.estrelas != null){
    rating = parseFloat(item.estrelas.replace(',', '.'));
  }
  let percentual = parseFloat(item.diferenca_percentual).toFixed(0);
  let preco_antigo = item.preco_antigo
  if (percentual != 0) {
    percentual = percentual + "% OFF"; // Atribui a string concatenada à variável percentual
    preco_antigo = "R$"+preco_antigo
  } else {
    percentual = "Menor preço"; // Atribui a string "Menor preço" à variável percentual
    preco_antigo = " "
  }
  let reviews = item.reviews + " reviews"
  console.log(reviews)
  if(reviews == "undefinedreviews"||reviews == "undefined reviews"||reviews == "reviews" || reviews == "null reviews"){
    reviews = " "
  }

  return (
    <div className="card" onClick={() => handleCardClick(item.link_produto)} style={{ cursor: 'pointer', position: 'relative' }}>
      <div className="stars-and-reviews">
        <div className="Stars" style={{ '--rating': rating }} aria-label={`Rating of this product is ${item.estrelas} out of 5.`}></div>
        <p className="reviews"><span className='span-review'>{reviews}</span></p>
      </div>
      <div className="discount-label">{percentual}</div>
      <div className="card-image-wrapper">
        <img src={item.link_imagem} alt={item.nome_produto} className="card-image" />
        <div className="icon-wrapper" style={{ position: 'absolute', top: '0', left: '0' }}>
          <img src={imagem2} alt="Ícone" className="icon" />
        </div>
      </div>
      <div className="card-content">
        <h2 className="card-title">
          {item.nome_produto.length > 50 ? `${item.nome_produto.substring(0, 60)}...` : item.nome_produto}
        </h2>
        <p className="card-price old-price"><span>{preco_antigo}</span></p>
        <p className="card-price new-price">R$<span>{item.preco_desconto}</span></p>
      </div>
    </div>
  );
}

export default ProductItem;
