import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

function SearchBar({ searchTerm, setSearchTerm, filters, setFilters }) {
  return (
    <div className="search-bar-container">
      <input
        type="text"
        placeholder="Buscar produtos..."
        className="search-bar"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="filter-container">
        <div className="price-filters">
          <div className="filter-item">
            <FontAwesomeIcon icon={faFilter} />
            <input
              type="number"
              placeholder="Preço mínimo"
              value={filters.minPrice}
              onChange={(e) => setFilters({ ...filters, minPrice: e.target.value })}
            />
          </div>
          <div className="filter-item">
            <FontAwesomeIcon icon={faFilter} />
            <input
              type="number"
              placeholder="Preço máximo"
              value={filters.maxPrice}
              onChange={(e) => setFilters({ ...filters, maxPrice: e.target.value })}
            />
          </div>
        </div>
        <button onClick={() => setFilters({ ...filters, discount: !filters.discount })} className={`filter-button ${filters.discount ? 'active' : ''}`}>
          <FontAwesomeIcon icon={filters.discount ? faArrowDown : faArrowUp} />
          Desconto
        </button>
      </div>
    </div>
  );
}

export default SearchBar;
