import { getAuthHeader } from './auth';
import imagem_icon from '../resources/img/Amazon_icon.png';
import imagem_icon_posi from '../resources/img/positivo_casa.png';
import imagem_girafa from '../resources/img/girafa_logo.png';


export function imagem(empresa) {
  if (empresa === "Amazon") {
    return imagem_icon;
  } else if (empresa === "Positivo Casa Inteligente") {
    return imagem_icon_posi;
  } else if (empresa === "Girafa") {
    return imagem_girafa;
  }
  return null;
}

export function handleCardClick(link_produto) {
  window.open(link_produto, '_blank');
}

export async function fetchProductList(categoriaId, setProductList, setLoading, setHasMoreItems, limit, reset = false) {
  try {
    setLoading(true);
    let response;
    if (categoriaId !== 1500) {
      response = await fetch(`https://app.sulahub.com.br/categoria/${categoriaId}`, {
        headers: getAuthHeader()
      });
      if (reset) setProductList([]);
    } else {
      response = await fetch(`https://app.sulahub.com.br/pagina_inicial/${0}/${limit}`, {
        headers: getAuthHeader()
      });
      if (reset) setProductList([]);
      if (reset) setHasMoreItems(true);
    }
    if (!response.ok) {
      console.error(`Erro ao buscar dados: ${response.statusText}`);
      throw new Error('Erro ao buscar dados');
    }
    const data = await response.json();
    const products = data.flatMap(innerArray => innerArray);
    if (products.length < limit) {
      setHasMoreItems(false);
    }
    setProductList(products);
  } catch (error) {
    console.error('Erro:', error);
  } finally {
    setLoading(false);
  }
}

export async function fetchMoreProductList(page, setProductList, setLoading, setHasMoreItems, limit) {
  try {
    setLoading(true);
    const response = await fetch(`https://app.sulahub.com.br/pagina_inicial/${page * limit}/${limit}`, {
      headers: getAuthHeader()
    });
    if (!response.ok) {
      console.error(`Erro ao buscar dados: ${response.statusText}`);
      throw new Error('Erro ao buscar dados');
    }
    const data = await response.json();
    const products = data.flatMap(innerArray => innerArray);
    if (products.length < limit) {
      setHasMoreItems(false);
    }
    setProductList(prevProductList => [...prevProductList, ...products]);
  } catch (error) {
    console.error('Erro:', error);
  } finally {
    setLoading(false);
  }
}

export function applyFilters(items, filters) {
  let filteredItems = items;

  if (filters.minPrice) {
    filteredItems = filteredItems.filter(item => parseFloat(item.preco_desconto) >= parseFloat(filters.minPrice));
  }

  if (filters.maxPrice) {
    filteredItems = filteredItems.filter(item => parseFloat(item.preco_desconto) <= parseFloat(filters.maxPrice));
  }

  if (filters.recent) {
    filteredItems = [...filteredItems].sort((a, b) => new Date(b.data_adicionado) - new Date(a.data_adicionado));
  }

  if (filters.discount) {
    filteredItems = [...filteredItems].sort((a, b) => parseFloat(b.diferenca_percentual) - parseFloat(a.diferenca_percentual));
  }

  return filteredItems;
}


