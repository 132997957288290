import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import ProductList from '../components/ProductList'; // Importe o componente ProductList
import '../Styles/index.css'; // Importe seu arquivo de estilo
import '../Styles/navbar.css'; // Importe seu arquivo de estilo

function Menu() {
  const [categoriaId, setCategoriaId] = useState(1500);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    document.body.className = isDarkMode ? 'dark-mode' : '';
  }, [isDarkMode]);

  function handleMenuClick(id) {
    setCategoriaId(id);
  }

  return (
    <React.StrictMode>
      <div>
        <section className="top-nav">
          <input id="menu-toggle" type="checkbox" />
          <label className="menu-button-container" htmlFor="menu-toggle">
            <div className="menu-button"></div>
          </label>
          <ul className="menu">
            <a className="logo" onClick={() => handleMenuClick(1500)}>
              SulaHub
            </a>
            <li onClick={() => handleMenuClick(1500)}><a href="#">Home</a></li>
            <li onClick={() => handleMenuClick(12, 13, 14, 15)}><a href="#">Eletrônicos</a></li>
            <li onClick={() => handleMenuClick(1, 2)}><a href="#">Saúde e higiene</a></li>
            <li onClick={() => handleMenuClick(3, 4)}><a href="#">Papelaria</a></li>
            <li onClick={() => handleMenuClick(5, 6)}><a href="#">Beleza</a></li>
            <li onClick={() => handleMenuClick(7, 8)}><a href="#">Brinquedos</a></li>
            <li onClick={() => handleMenuClick(9, 10, 11)}><a href="#">Eletrodomésticos</a></li>
          </ul>
          <button onClick={() => setIsDarkMode(!isDarkMode)} className="mode-toggle-button-1">
            <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />
          </button>
        </section>
        <div className="container">
          <ProductList categoriaId={categoriaId} isDarkMode={isDarkMode} />
        </div>
      </div>
    </React.StrictMode>
  );
}

export default Menu;
